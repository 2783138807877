/* You can add global styles to this file, and also import other style files */
@import '~motion-styles/scss/motion-stylesheet.scss';
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';

@import '~admin-lte/dist/css/adminlte.min.css';

@import '~ngx-toastr/toastr';

body {
    width: 100vw;
    height: 100vh;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

$breakpoint-latop-1024: 1024px;
$breakpoint-iPad: 768px;
$breakpoint-mobile-big: 600px;
$breakpoint-mobile-small: 500px;

.cdk-overlay-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    .cdk-overlay-connected-position-bounding-box {
        .cdk-overlay-pane.mat-tooltip-panel-below.mat-tooltip-panel {
            position: absolute !important;
            font-size: 0.65rem;
            font-family: 'Montserrat';
            font-weight: 600;
            background-color: #000000;
            border-radius: 10px;
        }
    }
}
.cdk-visually-hidden {
    position: fixed;
}

.far::before,
.fas::before {
    font-family: unset !important;
    font-weight: unset !important;
    font-size: unset !important;
}

.main-sidebar {
    height: 100vh;
    overflow-y: hidden;
    z-index: 1038;
}
.main-sidebar, .main-sidebar::before {
    transition: margin-left .3s ease-in-out,width .3s ease-in-out;
    width: 250px;
}
.nav-sidebar .nav-item>.nav-link {
    position: relative;
}

.sidebar-mini .main-sidebar .nav-link, .sidebar-mini-md .main-sidebar .nav-link, .sidebar-mini-xs .main-sidebar .nav-link {
    width: calc(250px - 0.6rem * 2);
    transition: width ease-in-out .3s;
}

.nav-sidebar .nav-item>.nav-link {
    margin-bottom: 0.2rem;
}

*, ::after, ::before {
    box-sizing: border-box;
    font-size: unset;
}

.main-sidebar .brand-text, .main-sidebar .logo-xl, .main-sidebar .logo-xs, .sidebar .nav-link p, .sidebar .user-panel .info {
    transition: margin-left .3s linear,opacity .3s ease,visibility .3s ease;
}

.fix-line .modal-content {
    border: 0 !important;
}
